const sitesWithPseudoTranslation = [
  'https://dev.shotgrid.autodesk.com',
  'https://shotgunlocalhost.com',
];

// Locale officially supported at GA.
const supportedLocales = ['en', 'ja', 'ko', 'zh-Hans'];

// Every locale not yet ready.
const notTranslatedLocales = [];

// In production, everything that's translated should be available.
const productionLocales = supportedLocales.filter(
  n => !notTranslatedLocales.includes(n)
);

// Environments other than production may support other locales - pseudotranslation.
// And yes, pt should be portuguese.
const runtimeLocales = sitesWithPseudoTranslation.includes(
  process.env.NEXTAUTH_URL
)
  ? productionLocales.concat('pt')
  : productionLocales;

module.exports = {
  supportedLocales,
  notTranslatedLocales,
  productionLocales,
  runtimeLocales,
};
