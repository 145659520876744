import Head from 'next/head';
import React, { FC, ReactNode } from 'react';

import { useDesignLanguage } from '@sgds/design-language-react';

const GlobalStyles: FC<{ children: ReactNode }> = ({ children }) => {
  const { basicsValues } = useDesignLanguage();

  return (
    <>
      <Head>
        <style>
          {`
          :root {
            --font-size-x-small: ${basicsValues.fontSize.X_SMALL}; /* 0.75rem */
            --font-size-small: ${basicsValues.fontSize.SMALL}; /* 0.875rem */
            --font-size-medium: ${basicsValues.fontSizes.medium}; /* 0.875rem */ 
            --font-size-large: ${basicsValues.fontSizes.large};
            --font-weight-regular: ${basicsValues.fontWeight.REGULAR};
            --font-weight-bold: ${basicsValues.fontWeights.bold};
            --color-gray-050: ${basicsValues.color.GRAY_050};
            --color-white: ${basicsValues.color.WHITE}; /* white */
            --color-gray-061: ${basicsValues.color.GRAY_061};
            --color-black: ${basicsValues.colors.primary.black}; /* black */
            --color-green: ${basicsValues.color.GREEN}; /* #87bc40 */
          }
        `}
        </style>
      </Head>
      {children}
    </>
  );
};

export default GlobalStyles;
