import type { AppProps } from 'next/app'; // https://nextjs.org/docs/basic-features/typescript#custom-app
import { Provider as SessionProvider } from 'next-auth/client'; // https://next-auth-git-canary.nextauthjs.vercel.app/getting-started/example
import React from 'react';

import '@sgds/react/dist/static/css/normalize.css';
import '@sgds/react/dist/static/fonts/ArtifaktElement/ArtifaktElement.css';
import '@sgds/react/dist/static/fonts/SourceCodePro/SourceCodePro.css';
import homeAppWithTranslation from '@/middlewares/next-i18next/homeAppWithTranslation';
import { initializeLogging } from '@/middlewares/ulog';

import { RootStyle } from '@sgds/component-root-style';
import designLanguage from '@sgds/design-language';
import { DesignLanguageProvider } from '@sgds/design-language-react';

import GlobalStyles from '../styles/GlobalStyles';
import '../styles/GlobalStyle.css';

initializeLogging();

const Home = ({ Component, pageProps }: AppProps) => (
  <DesignLanguageProvider
    designLanguage={designLanguage}
    themeId={designLanguage.defaultThemeId}
  >
    <GlobalStyles>
      <RootStyle
        style={{ width: '100%', height: '100%', background: '#ffffff' }}
      >
        <SessionProvider session={pageProps.session}>
          <Component {...pageProps} />
        </SessionProvider>
      </RootStyle>
    </GlobalStyles>
  </DesignLanguageProvider>
);

export default homeAppWithTranslation(Home);

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
// MyApp.getInitialProps = async (appContext: AppContext) => {
//   // calls page's `getInitialProps` and fills `appProps.pageProps`
//   const appProps = await App.getInitialProps(appContext);

//   return { ...appProps }
// }
