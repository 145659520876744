import ulog from 'ulog'; // disable colors
import defaultFormat from 'ulog/mods/formats';
import boolean from 'ulog/mods/props/boolean';

const initializeLogging = (): void => {
  // https://github.com/Download/ulog/issues/65
  const changeDefault = {
    use: defaultFormat,
    settings: {
      colored: {
        config: 'log_color',
        prop: boolean({
          default: `${process.env.FORCE_COLOR}` === '0' ? 'no' : 'yes',
        }),
      },
    },
  };
  ulog.use(changeDefault);
};

export { initializeLogging };
