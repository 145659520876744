/**
 * Never use String literals for the relative navigation.
 * Single point of access to all available routes.
 */
const URLS = {
  PROTOCOL: 'https://',
  COSV2_HEALTH_ROUTE: '/health/',
  API_DOWNLOAD_RELEASE_ROUTE: '/api/v1/release/',
  API_HEALTH_ROUTE: '/api/v1/health/',
  API_LICENSE_ROUTE: '/api/v1/license/',
  API_LIST_SITES_ROUTE: '/api/v1/sites/',
  RELEASES_PAGE_ROUTE: '/releases',
  AUTH_SIGNIN_ROUTE: '/auth/signin',
  AUTH_VERIFY_REQUEST_ROUTE: '/auth/verify-request',
  AUTH_NEXTAUTH_CALLBACK: '/api/auth/callback/forge',
  SITE_INITAUTH_ROUTE: '/forge/init_authentication',
  PRODUCT_URL: 'https://www.shotgridsoftware.com',
  SUPPORT_URL: 'https://knowledge.autodesk.com/support/shotgrid',

  // IMPORTANT
  // make sure this stays in sync with sitesWithPseudoTranslation variable in supported_locales.js.
  // TODO: have it inlined by webpack, if possible, instead.
  PSEUDOTRANSLATION_SITES_URLS: [
    'https://dev.shotgrid.autodesk.com',
    'https://shotgunlocalhost.com',
  ],

  getSiteURL: baseURL =>
    new URL(URLS.SITE_INITAUTH_ROUTE, `${URLS.PROTOCOL}${baseURL}`),
};

module.exports = URLS;
