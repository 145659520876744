import Pseudo from 'i18next-pseudo';
import type { AppProps } from 'next/app';
import { appWithTranslation } from 'next-i18next';
import type { UserConfig } from 'next-i18next';
import { FunctionComponent } from 'react';

import URLS from '@/lib/urls';
import nextI18NextConfig from '@/root/next-i18next.config';

/**
 * Add pseudo-translation support through the 'pt' locale.
 * All strings for the 'pt' locale are missing, using 'en' as fallback source.
 */
const extendConfigWithPseudoTranslation = (config: UserConfig): UserConfig => {
  // Production sites should not support pseudo translation. return config as is.
  if (!URLS.PSEUDOTRANSLATION_SITES_URLS.includes(process.env.NEXTAUTH_URL)) {
    return config;
  }

  const pseudo = new Pseudo({
    enabled: URLS.PSEUDOTRANSLATION_SITES_URLS.includes(
      process.env.NEXTAUTH_URL
    ),
    languageToPseudo: 'pt',
  });

  return {
    postProcess: ['pseudo'],
    fallbackLng: { pt: ['en'] },
    use: [pseudo],
    interpolation: {
      format(value, format) {
        if (format === 'uppercase') return value.toUpperCase();
        if (format === 'lowercase') return value.toLowerCase();
        return value;
      },
    },

    ...config,
  };
};

const homeAppWithTranslation = (
  app: FunctionComponent<AppProps>
): FunctionComponent<AppProps> =>
  appWithTranslation(app, extendConfigWithPseudoTranslation(nextI18NextConfig));

export default homeAppWithTranslation;
