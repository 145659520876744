/* eslint-disable eslint-comments/no-restricted-disable -- remove restrictions */
/* eslint-disable import/order -- must use require in next-i18next.config.js */
/* eslint-disable @typescript-eslint/no-var-requires -- must use require in next.config.js */
const localConfig = require('./config/supported_locales');

/* eslint-enable eslint-comments/no-restricted-disable -- remove restrictions */
/* eslint-enable import/order -- must use require in next-i18next.config.js */
/* eslint-enable @typescript-eslint/no-var-requires -- must use require in next.config.js */

const i18n /* i18next' InitOptions type */ = {
  debug: false,
  defaultNS: 'common',
  fallbackNS: 'common', // i18next should use the common namespace for any key it can't find in the translator namespace
  localePath: './public/locales',
  preload: localConfig.productionLocales, // optimization for production
  react: {
    useSuspense: false,
    wait: true,
  },
};

const nexti18nextConfig /* next-i18next' NextJsI18NConfig type */ = {
  defaultLocale: 'en',
  locales: localConfig.runtimeLocales,
};

// Kinda weird format: i18next are the top-level and next-i18next props go under i18n attribute.
// See https://github.com/isaachinman/next-i18next#5-advanced-configuration and TypeScript types.

module.exports = {
  i18n: nexti18nextConfig,
  ...i18n,
};
